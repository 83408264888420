import {
    mci_verification
} from "../../js/path";
import moment from "moment";

export default {
    data() {
        return {
            testing:true,
            pageNo: null,
            mobile_number: '',
            verified: null,
            member_id: null,
            oldVerified: '',
            activeTab: 'processing-without',
            value: null,
            mciStates: [],
            qualification: '',
            message:'',
            messages: [],
            fields: [{
                    key: "member_id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "name",
                    label: "Member Name",
                    sortable: true,
                    class: "c_touchpt_header",
                },
                {
                    key: "speciality",
                    label: "Speciality",
                    sortable: true,
                    class: "c_touchpt_header",
                },
                {
                    key: "city",
                    class: "c_touchpt_header",
                },
                 {
                    key: "email",
                    label: "Email ID",
                    sortable: true
                },
                {
                    key: "verified",
                    label: "Verification Status",
                    class: "c_touchpt_header",
                },
                {
                    key: "show_details",
                    label: "Edit",
                    sortable: false,
                },
            ],
            verified_options: [{
                    text: "Select Type",
                    value: "0",
                },
                {
                    text: "Processing",
                    value: "3",
                },
                {
                    text: "Verified",
                    value: "1",
                },
                {
                    text: "Not Verified",
                    value: "2",
                },
                {
                    text: "Not Valid User",
                    value: "5",
                },
            ],
            filter_user_options: [
                {
                    text: "Pending",
                    value: "pending"
                },
                {
                    text: "Verified",
                    value: "verified"
                },
                {
                    text: "Processing",
                    value: "processing"
                },
                {
                    text: "Block user",
                    value: "blockUser"
                },
                {
                    text: "Demo Account",
                    value: "demoAccount"
                },
                {
                    text: "Not verified",
                    value: "not_verified"
                },
            ],
            filter: null,
            assign: false,
            selected_user: "verified",
            filterOn: [],
            sortBy: "",
            sortDesc: true,
            statusUpdate: "",
            currentPage: 1,
            tableData: [],
            key: 0,
            params: '&page=1',
            admin_id: this.admin_id,
            mod_val: ''
        };
    },
    methods: {
        async updateLeadStatus(id) {
            this.$store.commit("loader/updateStatus", true);
            try {
                let index = this.tableData.data.findIndex((e) => e.member_id === id);
                const url = mci_verification.updateLeadProfileStatus;
                const data = await this.postRequest(url, {
                    member_id: id,
                    status: (this.tableData.data[index].is_lead_profile_approved) ? 1:0 
                });
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
            } catch (err) {
                this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: err.data ? err.data.message : "Please try again!",
                });
            }
            this.$store.commit("loader/updateStatus", false);
        },
        getSessionStart : function (session_time) {
            let dateTime1 = moment(session_time).format('LLLL');
            return dateTime1;
        },
        search(event) {
            if (this.filter.length > 1) {
                if (event.keyCode == 13) {
                    this.fetchData(this.activeTab);
                }
            } else if (this.filter.length == 0) this.fetchData(this.activeTab);
        },
        searchFor() {
            if (this.filter.length > 0) {
                this.fetchData(this.activeTab);
            } else {
                this.fetchData(this.activeTab);
            }
        },
        filterPage() {
            if (this.pageNo.length > 0) {
                this.params = `&page=${this.pageNo}`;
                this.fetchData(this.activeTab);
                this.currentPage = this.pageNo;
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async fetchData(txt) {
            let url = "";
            if (txt == "pending") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "verified") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            }  else if (txt == "demoAccount") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "processing") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "blockUser") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else if (txt == "not_verified") {
                this.activeTab = txt;
                if (this.filter != null && this.filter != "") {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt + "&search=" + this.filter;
                } else {
                    url = mci_verification.fetchMciVerification + "?filter=" + txt;
                }
            } else {
                url += '?'
            }
            this.getUrl();
            url += this.params;
            this.$store.commit("loader/updateStatus", true);
            try {
                const data = await this.getRequest(url);
                if (data.status) {
                    const responseData = data.response;
                    this.tableData = responseData;
                }
            } catch (err) {
                this.$store.commit("toast/updateStatus", {
                    status: true,
                    icon: "error",
                    title: err.data ? err.data.message : "Please try again!",
                });
            }
            this.$store.commit("loader/updateStatus", false);
            this.key += 1;
        },
        mciDetails(val) {
            let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
            this.oldVerified = this.tableData.data[index].verified;
            this.tableData.data[index].qualifications_by_speciality.push({
                "course_name": "Other",
                "id": '02',
                "qualification_detail_master_ref_no": "Other",
                "qualification_master_ref_no": "Other",
                "speciality_id": '02',
            });
        },
        async changeStatus(val) {
            this.isClickedMciState = false;
            try {
                if (val.item.verified == 1) {
                    if (val.item.mci_document == "" || val.item.mci_document == null || val.item.mci_document == 'null') {
                        let res = await this.$bvModal.msgBoxOk('Please add document first', {
                            title: 'Confirmation',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true,
                        })
                        if (res == true) {
                            let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                            if (index != -1) {
                                this.tableData.data[index].verified = this.oldVerified;
                            }
                        }
                    } else {
                        let res = await this.$bvModal.msgBoxConfirm('Are you sure you want to Proceed?', {
                            title: 'Confirmation',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true,
                        });
                        if (res == false) {
                            let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                            if (index != -1) {
                                this.tableData.data[index].verified = this.oldVerified;
                            }
                        } else {
                            const url = mci_verification.mciUpdate;
                            let dataAppend = new FormData();
                            dataAppend.append("is_verified_with_mci", val.item.verified);
                            dataAppend.append("id", val.item.member_id);
                            const data = await this.postRequest(url, dataAppend);
                            if (data.status) {
                                this.$store.commit("toast/updateStatus", {
                                    status: true,
                                    icon: "success",
                                    title: data.message,
                                });
                            }
                        }
                    }
                } else {
                    const url = mci_verification.mciUpdate;
                    let dataAppend = new FormData();
                    dataAppend.append("is_verified_with_mci", val.item.verified);
                    dataAppend.append("id", val.item.member_id);
                    const data = await this.postRequest(url, dataAppend);
                    if (data.status) {
                        this.$store.commit("toast/updateStatus", {
                            status: true,
                            icon: "success",
                            title: data.message,
                        });
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async uploadMciFile(e, val) {
            this.mci_file = e.target.files[0];
            try {
                const url = mci_verification.mciUpload;
                let dataAppend = new FormData();
                dataAppend.append("mci_reg", this.mci_file);
                dataAppend.append("member_id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                    if (index != -1) {
                        this.image = this.tableData.data[index].mci_document;
                        this.tableData.data[index].mci_document = data.response.mci_document;
                    }
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                    this.$refs.mci_file.reset();
                }
            } catch (err) {
                console.log(err);
            }
        },
        async submitEmail(val) {
            try {
                const url = mci_verification.mciEmailSubmit;
                let dataAppend = new FormData();
                dataAppend.append("email", val.item.email);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedEmail = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitMobile(val) {
            try {
                const url = mci_verification.mciMobileSubmit;
                let dataAppend = new FormData();
                dataAppend.append("mobile_number", val.item.mobile_number);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedPhone = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitMciNo(val) {
            try {
                const url = mci_verification.mciNoSubmit;
                let dataAppend = new FormData();
                dataAppend.append("mci_no", val.item.mci_no);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciNo = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitMciYear(val) {
            try {
                const url = mci_verification.mciYearSubmit;
                let dataAppend = new FormData();
                dataAppend.append("mci_year", val.item.mci_year);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciYear = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitName(val) {
            try {
                const url = mci_verification.mciNameSubmit;
                let dataAppend = new FormData();
                dataAppend.append("first_name", val.item.first_name);
                dataAppend.append("last_name", val.item.last_name);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedName = false;
            } catch (err) {
                console.log(err);
            }
        },
        async submitMciReferrel(val) {
            try {
                const url = mci_verification.referredBySubmit;
                let dataAppend = new FormData();
                dataAppend.append("referred_by", val.item.referred_by);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciReferrel = false;
            } catch (err) {
                console.log(err);
            }
        },
        async storeNotes(val,message) {
            try {
                const url = mci_verification.storeNotes;
                let dataAppend = new FormData();
                dataAppend.append("member_id", val);
                dataAppend.append("mci_note", message);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                    this.sendInfoNote(val);
                    this.message = "";
                }
                this.isClickedMciReferrel = false;
            } catch (err) {
                console.log(err);
            }
        },
        async changeMciState(val) {
            try {
                const url = mci_verification.fetchUpdateMciState;
                let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                this.tableData.data[index].mci_state = val.item.mci_state;
                console.log(this.tableData.data[index].mci_state);
                let dataAppend = new FormData();
                dataAppend.append("mci_state", val.item.mci_state);
                dataAppend.append("id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
                this.isClickedMciState = false;
            } catch (err) {
                console.log(err);
            }
        },
        async fetchUpdateMciState() {
            const url = mci_verification.fetchUpdateMciState;
            console.log(url);
        },
        async fetchState() {
            const url = mci_verification.fetchMciVerification;
            const data = await this.getRequest(url);
            if (data.status) {
                const responseData = data.response;
                if (responseData.data != '' && responseData.data != null) {
                    responseData.data.map((item) => {
                        this.mciStates = item.state_councils.map(val => {
                            return val.state;
                        });
                    });
                }
            }
        },
        async fetchNotes(val) {
            const url = mci_verification.fetchNotes + val;
            const data = await this.getRequest(url);
            this.messages = data.response;
        },
        async setQualification(val,event){
            try {
                const url = mci_verification.updateQualification;
                let index = this.tableData.data.findIndex(f => f.member_id == val.item.member_id);
                this.tableData.data[index].qualification = val.item.qualification;
                let dataAppend = new FormData();
                dataAppend.append("qualification", event);
                dataAppend.append("member_id", val.item.member_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                }
            } catch (err) {
                console.log(err);
            }
        },
        async submitCallToDoctor(val) {
            try {
                const url = mci_verification.doctorAssign;
                let dataAppend = new FormData();
                dataAppend.append("member_ref_no", val.item.member_ref_no);
                dataAppend.append("digi_mr_id", val.item.digiMR_id);
                const data = await this.postRequest(url, dataAppend);
                if (data.status) {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "success",
                        title: data.message,
                    });
                } else {
                    this.$store.commit("toast/updateStatus", {
                        status: true,
                        icon: "error",
                        title: data.message,
                    });
                }
                this.isClickedCallDoctor = false;
            } catch (err) {
                console.log();
            }
        },
        getUrl() {
            var url = new URL(window.location);
            if (this.activeTab == null || this.activeTab == "") {
                this.activeTab = "verified";
            }
            (url.searchParams.has('filter') ? url.searchParams.set('filter', this.activeTab) : url.searchParams.append('filter', this.activeTab));
            if (this.filter != null && this.filter != "") {
                (url.searchParams.has('search') ? url.searchParams.set('search', this.filter) : url.searchParams.append('search', this.filter));
            } else {
                url.searchParams.delete('search');
            }
            if (this.currentPage != null && this.currentPage != "") {
                (url.searchParams.has('page') ? url.searchParams.set('page', this.currentPage) : url.searchParams.append('page', this.currentPage));
            } else {
                url.searchParams.set('page', 1);
            }
            url.search = url.searchParams;
            url = url.toString();
            history.pushState({}, null, url);
        },
    },
    watch: {
        tableData(v) {
            if (v) {
                return (this.tableData = v);
            }
        },
        currentPage: {
            handler: function (value) {
                if (typeof value != undefined) {
                    this.params = `&page=${value}`;
                    this.fetchData(this.activeTab);
                    this.pageNo = this.currentPage;
                }
            },
        },
    },
    mounted() {
        this.fetchState();
        this.admin_id = localStorage.getItem('admin_id');
        // console.log(admin_id);
        if(this.admin_id == '73'){
            this.mod_val = '3';
        }
        if(this.admin_id == '57'){
            this.mod_val = '2';
        }
        if(this.admin_id == '55'){
            this.mod_val = '1';
        }
        if(this.admin_id == '56'){
            this.mod_val = '0';
        }
        this.activeTab = "verified";
        if (this.$route.query.filter) {
            this.activeTab = this.$route.query.filter;
            this.selected_user = this.$route.query.filter;
        }
        if (this.$route.query.search) {
            this.filter = this.$route.query.search;
        }
        if (this.$route.query.color) {
            this.color = this.$route.query.color;
        }
        if (this.$route.query.assign) {
            this.assign = this.$route.query.assign;
        }
        if (this.$route.query.page && this.$route.query.page != 1) {
            this.currentPage = parseInt(this.$route.query.page);
        } else {
            this.fetchData(this.activeTab);
        }
    },
};